import React from "react";
import Head from "next/head";
import { AppProps } from "next/app";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "../lib/apolloClient";
import "src/styles/globals.css";
import Bugsnag from "@bugsnag/js";
import Error from "./_error";

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

function MyApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps.initialApolloState);

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <ApolloProvider client={apolloClient}>
        <Head>
          <title>Kohai</title>
          <script
            dangerouslySetInnerHTML={{
              __html: `window.fwSettings={'widget_id':66000001985};
              !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() `,
            }}
          />
          <script
            type="text/javascript"
            src="https://widget.freshworks.com/widgets/66000001985.js"
            async
            defer
          ></script>
        </Head>
        <Component {...pageProps} />
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
